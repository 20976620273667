import React from "react";
import styled from "styled-components";

type ProgressBarProps = {
  value: number;
  max?: number;
};

const StyledBarContainer = styled.div`
  height: 10px;
  width: 100%;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset;
  overflow: hidden;
`;

const StyledBar = styled.div.attrs({
  "aria-valuemin": 0,
  role: "progressbar",
})<{ "aria-valuenow": number; "aria-valuemax": number }>`
  height: 100%;
  width: ${(props) =>
    Math.floor((props["aria-valuenow"] / props["aria-valuemax"]) * 100)}%;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.mint[600]};
  transition: width 0.6s ease 0s;
`;

const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ value, max = 100 }, ref) => (
    <StyledBarContainer ref={ref}>
      <StyledBar aria-valuenow={value} aria-valuemax={max} />
    </StyledBarContainer>
  ),
);

export default ProgressBar;
