import { css } from "styled-components";

type GroupedLabelledInputStylesProps = {
  checked?: boolean;
  invalid?: boolean;
};

const GroupedLabelledInputStyles = css<GroupedLabelledInputStylesProps>`
  padding: ${(props) => props.theme.spacings.small}
    ${(props) => props.theme.spacings.medium};
  position: relative;
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.main};
  border-radius: 0;

  &:first-child {
    border-top-left-radius: ${(props) => props.theme.borders.radius.medium}px;
    border-top-right-radius: ${(props) => props.theme.borders.radius.medium}px;
  }

  &:last-child {
    border-bottom-left-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
    border-bottom-right-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
  }

  background: ${(props) => props.theme.palette.white};
  cursor: pointer;

  &:hover {
    z-index: 2;
    background: ${(props) => props.theme.palette.brand[200]};
    border-color: ${(props) => props.theme.palette.brand.dark};
  }

  ${(props) =>
    props.checked &&
    css`
      z-index: 1;
      background: ${props.theme.palette.brand[100]};
      border-color: ${props.theme.palette.brand.dark};
    `}

  ${(props) =>
    props.invalid &&
    css`
      background: ${props.theme.palette.error[100]};
      border-color: ${props.theme.palette.error.main};

      &:hover {
        background: ${props.theme.palette.error[200]};
      }

      &:hover {
        z-index: 1;
        border-color: ${props.theme.palette.error.main};
      }
    `}

  ${(props) =>
    props.checked &&
    props.invalid &&
    css`
      background: ${props.theme.palette.error[100]};
      z-index: 1;
      border-color: ${props.theme.palette.error.main};
    `}
`;

export default GroupedLabelledInputStyles;
