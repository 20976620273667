import Link from "../../../../../../packages/design-system/src/Link";
import Text from "../../../../../../packages/design-system/src/Text";
import CloseIcon from "../../../../../../packages/design-system/src/Icons/CloseIcon";
import Search1Icon from "../../../../../../packages/design-system/src/Icons/Search1Icon";
import * as React from 'react';
export default {
  Link,
  Text,
  CloseIcon,
  Search1Icon,
  React
};