import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import BusinessLiabilityInsuranceIcon from "../../../../../../packages/design-system/src/InsuranceIcons/BusinessLiabilityInsuranceIcon";
import * as insuranceIcons from "../../../../../../packages/design-system/src/InsuranceIcons";
import * as React from 'react';
export default {
  Spacings,
  Text,
  BusinessLiabilityInsuranceIcon,
  insuranceIcons,
  React
};