import styled from "styled-components";

import { VERTICAL_FLAG_WIDTH } from "../VerticalFlag";

const Grid = styled.header`
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    -ms-grid-columns: 1fr ${(props) => props.theme.layout.containerWidth} 1fr;
    grid-template-columns:
      1fr
      ${(props) => props.theme.layout.containerWidth}
      1fr;
  }
`;

const Background = styled.div`
  z-index: ${(props) => props.theme.zIndexes.header};
  -ms-grid-row: 2;
  grid-row: 2 / 3;
  -ms-grid-column: 1;
  grid-column: 1 / 2;
  display: flex;
  align-items: stretch;
  & > * {
    flex: 1;
  }
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1 / 4;
  }
`;

const TrustBarBackground = styled.div`
  background-color: ${(props) => props.theme.palette.brand[200]};
  -ms-grid-row: 1;
  grid-row: 1 / 2;
  -ms-grid-column: 1;
  grid-column: 1 / 2;
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1 / 4;
  }
`;

const TrustBar = styled.div`
  z-index: ${(props) => props.theme.zIndexes.header + 1};
  position: relative;
  -ms-grid-row: 1;
  grid-row: 1 / 2;
  -ms-grid-column: 1;
  grid-column: 1 / 2;
  padding-right: calc(
    ${(props) => props.theme.spacings.big} + ${VERTICAL_FLAG_WIDTH}
  );
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    -ms-grid-column: 2;
    grid-column: 2 / 3;
  }
`;

const Flag = styled.div`
  position: absolute;
  z-index: 1;
  right: ${(props) => props.theme.spacings.small};
  top: 0;
  display: none;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    display: block;
  }
`;

const Main = styled.div`
  z-index: ${(props) => props.theme.zIndexes.header};
  -ms-grid-row: 2;
  grid-row: 2 / 3;
  -ms-grid-column: 1;
  grid-column: 1 / 2;
  min-width: 0;
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    margin-right: 0;
    -ms-grid-column: 2;
    grid-column: 2 / 3;
  }
`;

export default { Grid, Background, Main, TrustBar, Flag, TrustBarBackground };
