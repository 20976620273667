import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled from "styled-components";

import Spacings from "../Spacings";
import Text from "../Text";

export const Container = styled(Text)`
  display: inline-flex;
  position: relative;
`;

export const RadioBox = styled(Spacings.Stack)`
  display: inline-flex;
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.main};
  padding: ${(props) => props.theme.spacings.medium};
  border-radius: ${(props) => props.theme.borders.radius.small}px;
  cursor: pointer;
  width: 100%;
  background: white;

  &:hover {
    background: ${(props) => props.theme.palette.gray[50]};
    border-color: ${(props) => props.theme.palette.brand.dark};
  }
`;

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.palette.gray[400]};
  font-size: 2em;

  svg {
    color: inherit;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:focus + ${/* sc-selector */ RadioBox} {
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }

  &:checked + ${/* sc-selector */ RadioBox} {
    background: ${(props) => props.theme.palette.brand[100]};
    border-color: ${(props) => props.theme.palette.brand.dark};

    ${/* sc-selector */ IconContainer} {
      color: ${(props) => props.theme.palette.brand.dark};
    }
  }
`;

export const LabelText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type StyledInputProps = React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;

type LabelledIconRadioProps = Omit<StyledInputProps, "type" | "className"> & {
  className?: string;
  icon: React.ReactNode;
  label: React.ReactNode;
};

const LabelledIconRadio: React.FC<LabelledIconRadioProps> = ({
  label,
  icon,
  className,
  ...radioProps
}) => (
  <Container as="label" className={className} isUncropped>
    <StyledInput type="radio" {...radioProps} />
    <RadioBox as="span">
      <IconContainer>{icon}</IconContainer>
      <LabelText>{label}</LabelText>
    </RadioBox>
  </Container>
);

export default LabelledIconRadio;
