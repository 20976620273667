import React, { useState } from "react";
import styled from "styled-components";

import PlayfulSelect from ".";
import Spacings from "../Spacings";

const CssReset = styled.div<{
  isSelectScaleMedium?: boolean;
}>`
  background-color: ${(props) =>
    props.isSelectScaleMedium
      ? props.theme.palette.gray[50]
      : props.theme.palette.white};

  ul {
    padding: 0 !important;
    margin: 0 !important;
  }

  li {
    padding-left: ${(props) =>
      props.isSelectScaleMedium
        ? props.theme.spacings.big
        : props.theme.spacings.medium} !important;
    margin: 0 !important;
  }
`;

export const DynamicPlayfulSelect = () => (
  <CssReset>
    <PlayfulSelect
      id="DynamicPlayfulSelect"
      name="DynamicPlayfulSelect"
      initialSelectedItem={{
        label: "Dropdown",
        value: "",
      }}
      items={[
        {
          label: "Label1",
          value: "Item1",
        },
        {
          label: "Label2",
          value: "Item2",
        },
        {
          label: "Label3",
          value: "Item3",
        },
        {
          label: "Label4",
          value: "Item4",
        },
      ]}
      onChange={(event) => event}
    />
  </CssReset>
);

export const DynamicPlayfulSelectMedium = () => (
  <CssReset isSelectScaleMedium>
    <Spacings.Inset scale="medium">
      <PlayfulSelect
        id="DynamicPlayfulSelectMedium"
        name="DynamicPlayfulSelectMedium"
        initialSelectedItem={{
          label: "Dropdown",
          value: "",
        }}
        items={[
          {
            label: "Label1",
            value: "Item1",
          },
          {
            label: "Label2",
            value: "Item2",
          },
          {
            label: "Label3",
            value: "Item3",
          },
          {
            label: "Label4",
            value: "Item4",
          },
        ]}
        onChange={(event) => event}
        scale="medium"
      />
    </Spacings.Inset>
  </CssReset>
);

export const DynamicPlayfulSelectWithScrollBar = () => (
  <CssReset>
    <PlayfulSelect
      id="DynamicPlayfulSelectWithScrollBar"
      name="DynamicPlayfulSelectWithScrollBar"
      initialSelectedItem={{
        label: "Dropdown",
        value: "",
      }}
      items={[
        {
          label: "Label1",
          value: "Item1",
        },
        {
          label: "Label2",
          value: "Item2",
        },
        {
          label: "Label3",
          value: "Item3",
        },
        {
          label: "Label4",
          value: "Item4",
        },
        {
          label: "Label5",
          value: "Item5",
        },
        {
          label: "Label6",
          value: "Item6",
        },
        {
          label: "Label7",
          value: "Item7",
        },
        {
          label: "Label8",
          value: "Item8",
        },
        {
          label: "Label9",
          value: "Item9",
        },
        {
          label: "Label10",
          value: "Item10",
        },
      ]}
      onChange={(event) => event}
    />
  </CssReset>
);

export const DynamicPlayfulSelectDisabled = () => (
  <CssReset>
    <PlayfulSelect
      id="DynamicPlayfulSelectDisabled"
      name="DynamicPlayfulSelectDisabled"
      initialSelectedItem={{
        label: "Dropdown",
        value: "",
      }}
      items={[
        {
          label: "Label1",
          value: "Item1",
        },
        {
          label: "Label2",
          value: "Item2",
        },
        {
          label: "Label3",
          value: "Item3",
        },
        {
          label: "Label4",
          value: "Item4",
        },
      ]}
      onChange={(event) => event}
      disabled
    />
  </CssReset>
);

export const DynamicPlayfulSelectInvalid = () => {
  // We need to use state to simulate removing the error when an answer is selected
  const [isInvalid, setIsInvalid] = useState(true);

  return (
    <CssReset>
      <PlayfulSelect
        id="DynamicPlayfulSelectInvalid"
        name="DynamicPlayfulSelectInvalid"
        initialSelectedItem={{
          label: "Dropdown",
          value: "",
        }}
        items={[
          {
            label: "Label1",
            value: "Item1",
          },
          {
            label: "Label2",
            value: "Item2",
          },
          {
            label: "Label3",
            value: "Item3",
          },
          {
            label: "Label4",
            value: "Item4",
          },
        ]}
        onChange={(event) => {
          setIsInvalid(false);
          return event;
        }}
        invalid={isInvalid}
      />
    </CssReset>
  );
};
