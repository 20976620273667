import React, { useState } from "react";
import styled from "styled-components";

import PlayfulInput from ".";
import FormLabel from "../FormLabel";
import Spacings from "../Spacings";

const StyledSpacingsStack = styled(Spacings.Stack)`
  max-width: 50%;
`;

const StyledMediumScaleWrapper = styled.div`
  max-width: 50%;
  margin-top: ${(props) => props.theme.spacings.massive};
`;

const StyledDisabledFormLabel = styled(FormLabel)`
  opacity: 0.5;
`;

export const ExamplePlayfulInput = () => {
  const [isValid, setIsValid] = useState(false);

  return (
    <StyledSpacingsStack>
      <FormLabel>Name</FormLabel>
      <PlayfulInput
        valid={isValid}
        onChange={(event) => {
          if (event.target.value !== "") {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }}
      />
    </StyledSpacingsStack>
  );
};

export const ExamplePlayfulInputMedium = () => {
  const [isValid, setIsValid] = useState(false);

  return (
    <StyledMediumScaleWrapper>
      <PlayfulInput
        label="Name"
        scale="medium"
        valid={isValid}
        onChange={(event) => {
          if (event.target.value !== "") {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }}
      />
    </StyledMediumScaleWrapper>
  );
};

export const ExamplePlayfulInputMediumInvalid = () => {
  const [isValid, setIsValid] = useState(false);

  return (
    <StyledMediumScaleWrapper>
      <PlayfulInput
        label="Name"
        scale="medium"
        invalid={!isValid}
        valid={isValid}
        onChange={(event) => {
          if (event.target.value !== "") {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }}
      />
    </StyledMediumScaleWrapper>
  );
};

export const ExamplePlayfulInputDisabled = () => (
  <StyledSpacingsStack>
    <StyledDisabledFormLabel>Name</StyledDisabledFormLabel>
    <PlayfulInput disabled />
  </StyledSpacingsStack>
);

export const ExamplePlayfulInputInvalid = () => {
  const [isValid, setIsValid] = useState(false);

  return (
    <StyledSpacingsStack>
      <FormLabel>Name</FormLabel>
      <PlayfulInput
        invalid={!isValid}
        valid={isValid}
        onChange={(event) => {
          if (event.target.value !== "") {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }}
      />
    </StyledSpacingsStack>
  );
};
