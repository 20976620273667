import FormInput from "../../../../../../packages/design-system/src/FormInput";
import FormInputAffix from "../../../../../../packages/design-system/src/FormInputAffix";
import FormInputGroup from "../../../../../../packages/design-system/src/FormInputGroup";
import { CustomFormInput } from "../../../../../../packages/design-system/src/FormInput/readme-helpers";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import * as React from 'react';
export default {
  FormInput,
  FormInputAffix,
  FormInputGroup,
  CustomFormInput,
  Spacings,
  React
};