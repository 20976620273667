import styled from "styled-components";

import FormTextarea from "./";

export const CustomFormTextarea = styled(FormTextarea)`
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
`;
