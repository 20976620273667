import React from "react";

import Text from "../Text";

type DataSummary = {
  label?: string;
  value: string | undefined | null | React.ReactNode;
};

const DataSummary: React.FC<DataSummary> = ({ label, value }) => (
  <span>
    {label ? (
      <Text as="span" priority="secondary" isUncropped>
        {label}
        {": "}
      </Text>
    ) : null}
    <Text as="span" isUncropped>
      {value}
    </Text>
  </span>
);

export default DataSummary;
