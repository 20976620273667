import styled from "styled-components";

export const CssReset = styled.div`
  & ol {
    margin-left: 0 !important;
    margin-bottom: 0 !important;

    li {
      margin-bottom: 0 !important;
    }
  }
`;
