import theme from "../../../../../../packages/design-system/src/designTokens.ts";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import DownloadIcon from "../../../../../../packages/design-system/src/Icons/DownloadIcon";
import MultiplePersonsIcon from "../../../../../../packages/design-system/src/Icons/MultiplePersonsIcon";
import WarehouseIcon from "../../../../../../packages/design-system/src/Icons/WarehouseIcon";
import * as genericIcons from "../../../../../../packages/design-system/src/Icons";
import * as React from 'react';
export default {
  theme,
  Spacings,
  Text,
  DownloadIcon,
  MultiplePersonsIcon,
  WarehouseIcon,
  genericIcons,
  React
};