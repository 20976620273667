import styled from "styled-components";

import GroupedLabelledInputStyles from "../GroupedLabelledInputStyles";
import LabelledCheckbox from "../LabelledCheckbox";

type GroupedLabelledCheckboxProps = {
  invalid?: boolean;
};

const GroupedLabelledCheckbox = styled(
  LabelledCheckbox,
)<GroupedLabelledCheckboxProps>`
  ${GroupedLabelledInputStyles}
`;

export default GroupedLabelledCheckbox;
