import Button from "../../../../../../packages/design-system/src/Button";
import Layer from "../../../../../../packages/design-system/src/Layer";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import CollapsibleStateContainer from "../../../../../../packages/design-system/src/CollapsibleStateContainer";
import * as React from 'react';
export default {
  Button,
  Layer,
  Spacings,
  Text,
  CollapsibleStateContainer,
  React
};