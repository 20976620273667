import styled from "styled-components";

import GroupedLabelledInputStyles from "../GroupedLabelledInputStyles";
import LabelledRadio from "../LabelledRadio";

type GroupedLabelledRadioProps = {
  invalid?: boolean;
};

const GroupedLabelledRadio = styled(LabelledRadio)<GroupedLabelledRadioProps>`
  ${GroupedLabelledInputStyles};
`;

export default GroupedLabelledRadio;
