import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import React from "react";
import styled, { StyledComponent } from "styled-components";

import DatePicker from "../DatePicker";
import useDialogState from "../Dialog/useDialogState";
import CalendarIcon from "../Icons/CalendarIcon";
import InputBox from "../InputBox";
import Text from "../Text";

const Container = styled.div`
  position: relative;
`;

type InputBoxProps = React.ComponentProps<typeof InputBox>;

const Toggle = styled(InputBox).attrs({
  as: "button",
  type: "button",
})`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
` as StyledComponent<"button", {}, Omit<InputBoxProps, "ref">>;

const parseValueToSelectedDate = (
  value: string | undefined,
): Date | undefined => {
  const parsedDateValue = value ? parseISO(value) : null;
  const selectedDate =
    parsedDateValue && isValid(parsedDateValue) ? parsedDateValue : undefined;

  return selectedDate;
};

const formatValue = (value: string | undefined): string => {
  if (!value) {
    return "";
  }

  const dateValue = new Date(value);

  return isValid(dateValue) ? format(dateValue, "dd.MM.yyyy") : value;
};

type Event = {
  target: {
    id?: string;
    name?: string;
    value: string;
  };
};

type DatePickerProps = React.ComponentProps<typeof DatePicker>;

type FormInputDateProps = {
  id?: string;
  name?: string;
  value?: string;
  invalid?: boolean;
  placeholder?: string;
  onChange?: (event: Event) => void;
} & Omit<
  DatePickerProps,
  "toggleRef" | "selectedDate" | "onDateSelect" | "visible" | "close"
>;

const FormInputDate: React.FC<FormInputDateProps> = ({
  id,
  name,
  value,
  invalid,
  placeholder,
  onChange,
  ...datePickerProps
}) => {
  const dialogState = useDialogState();

  const handleDateSelection = (date: Date) => {
    dialogState.close();

    if (!onChange) {
      return;
    }

    const newValue = format(date, "yyyy-MM-dd");

    onChange({
      target: {
        id,
        name,
        value: newValue,
      },
    });
  };

  const formattedValue = formatValue(value);

  return (
    <Container>
      <Toggle
        id={id}
        invalid={invalid}
        {...dialogState.toggle}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          dialogState.toggle.onClick();
          if (dialogState.visible) {
            dialogState.close();
          }
          event?.stopPropagation();
        }}
      >
        {formattedValue ? (
          <Text isUncropped>{formattedValue}</Text>
        ) : (
          <Text isUncropped priority="secondary">
            {placeholder}
          </Text>
        )}
        <CalendarIcon color="brand" />
      </Toggle>

      <DatePicker
        {...datePickerProps}
        placeholder={placeholder}
        close={dialogState.close}
        visible={dialogState.visible}
        toggleRef={dialogState.toggle.ref}
        selectedDate={parseValueToSelectedDate(value)}
        onDateSelect={handleDateSelection}
      />
    </Container>
  );
};

export default FormInputDate;
