import { useSelect, UseSelectStateChange } from "downshift";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import ChevronBottomIcon from "../Icons/ChevronBottomIcon";
import Text from "../Text";

type Scale = "small" | "medium";

const StyledSelectWrapper = styled.div<{
  disabled?: boolean;
  scale?: Scale;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.scale === "medium"
      ? props.theme.palette.white
      : props.theme.palette.gray[50]};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const StyledButton = styled.button<{
  isSelectOpen: boolean;
  hasAnswer: boolean;
  invalid: boolean;
  scale: Scale;
}>`
  color: ${(props) => props.theme.palette.gray[700]};
  background-color: ${(props) =>
    props.scale === "medium"
      ? props.theme.palette.white
      : props.theme.palette.gray[50]};
  border: 0;
  outline: none;
  border-top-left-radius: ${(props) => props.theme.borders.radius.medium}px;
  border-top-right-radius: ${(props) => props.theme.borders.radius.medium}px;
  border-bottom-left-radius: ${(props) =>
    props.isSelectOpen ? 0 : props.theme.borders.radius.medium}px;
  border-bottom-right-radius: ${(props) =>
    props.isSelectOpen ? 0 : props.theme.borders.radius.medium}px;
  padding: ${(props) =>
    props.scale === "medium"
      ? props.theme.spacings.big
      : props.theme.spacings.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.isSelectOpen &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `};
  ${(props) =>
    props.hasAnswer &&
    css`
      color: ${props.theme.palette.gray[900]};
      background-color: ${props.theme.palette.brand[200]};
    `};
  :focus {
    box-shadow: 0 0 0 4px ${(props) => props.theme.palette.brand[300]};
  }
  :hover {
    color: ${(props) => props.theme.palette.gray[900]};
    background-color: ${(props) => props.theme.palette.brand[300]};
    box-shadow: none;
  }
  ${(props) =>
    !props.isSelectOpen && props.invalid && !props.hasAnswer
      ? css`
          color: ${props.theme.palette.gray[900]};
          background-color: ${props.theme.palette.error[100]};
          :focus {
            background-color: ${props.theme.palette.error[200]};
            box-shadow: 0 0 0 4px ${props.theme.palette.error[300]};
          }
          :hover {
            background-color: ${props.theme.palette.error[200]};
            box-shadow: none;
          }
        `
      : null};
`;

const StyledUl = styled.ul<{ buttonHeight: number }>`
  width: 100%;
  max-height: 21.5rem;
  overflow-y: auto;
  position: absolute;
  top: ${(props) => props.buttonHeight}px;
  left: 0;
  z-index: ${(props) => props.theme.zIndexes.toolbar};
`;

const StyledLi = styled.li<{
  isHighlighted: boolean;
  scale: Scale;
}>`
  background-color: ${(props) =>
    props.scale === "medium"
      ? props.theme.palette.white
      : props.theme.palette.gray[50]};
  border-top: 1px solid
    ${(props) => props.theme.palette.gray[props.scale === "medium" ? 50 : 100]};
  padding: ${(props) =>
    props.scale === "medium"
      ? props.theme.spacings.big
      : props.theme.spacings.medium};
  :last-of-type {
    border-bottom-left-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
    border-bottom-right-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
  }
  ${(props) =>
    props.isHighlighted &&
    css`
      background-color: ${props.theme.palette.brand[300]};
    `};
  :active {
    background-color: ${(props) => props.theme.palette.brand[200]};
  }
`;

export type Item = {
  label: string;
  value: string;
};

export type Event = {
  target: {
    id: string;
    name: string;
    value: string;
  };
};

export type PlayfulSelectProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  initialSelectedItem: Item;
  invalid?: boolean;
  items: Item[];
  onChange?: (event: Event) => void;
  scale?: Scale;
  value?: Item;
  id?: string;
  name: string;
};

const PlayfulSelect: React.FC<PlayfulSelectProps> = ({
  autoFocus = false,
  disabled,
  id,
  initialSelectedItem,
  invalid,
  items,
  onChange,
  scale = "small",
  value,
  name,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [buttonHeight, setButtonHeight] = useState(0);

  const itemToString = (item: Item | null | undefined) =>
    item ? item.label : "";

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    initialSelectedItem: value,
    itemToString,
    items,
    onSelectedItemChange: (changes: UseSelectStateChange<Item>) => {
      const { value: val } = changes.selectedItem!;
      onChange?.({
        target: {
          id: id ? id : `${name}-${val}`,
          name,
          value: val,
        },
      });
    },
  });

  /**
   * We check values for the cases where the user clicks back or updates their answer.
   * If one of the value equals an answer then the button should have the blue "answered" state.
   */
  const hasAnswer =
    (Boolean(selectedItem) && selectedItem?.value !== "") ||
    initialSelectedItem.value !== "";

  useEffect(() => {
    setButtonHeight(buttonRef.current?.clientHeight || 0);
  }, [buttonRef]);

  return (
    <StyledSelectWrapper disabled={disabled}>
      <StyledButton
        type="button"
        scale={scale}
        isSelectOpen={isOpen}
        hasAnswer={hasAnswer}
        invalid={invalid}
        autoFocus={autoFocus}
        {...getToggleButtonProps({ disabled, ref: buttonRef })}
      >
        {itemToString(selectedItem) || itemToString(initialSelectedItem)}
        <ChevronBottomIcon />
      </StyledButton>
      <StyledUl buttonHeight={buttonHeight} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <StyledLi
              key={`${item}${index}`}
              scale={scale}
              isHighlighted={highlightedIndex === index}
              {...getItemProps({ item, index })}
            >
              <Text>{item.label}</Text>
            </StyledLi>
          ))}
      </StyledUl>
    </StyledSelectWrapper>
  );
};

export default PlayfulSelect;
