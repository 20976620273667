import React, { useState } from "react";
import styled from "styled-components";

import PlayfulInputPhone from ".";
import FormLabel from "../FormLabel";
import Spacings from "../Spacings";

const StyledSpacingsStack = styled(Spacings.Stack)`
  max-width: 70%;
`;

const StyledMediumScaleWrapper = styled.div`
  max-width: 70%;
  margin-top: ${(props) => props.theme.spacings.massive};
`;

const StyledDisabledFormLabel = styled(FormLabel)`
  opacity: 0.5;
`;

const CssReset = styled.div`
  li {
    margin: 0 !important;
  }
`;

export const ExamplePlayfulInputPhone = () => {
  const [value, setValue] = useState("");

  return (
    <CssReset>
      <StyledSpacingsStack>
        <FormLabel>Name</FormLabel>
        <PlayfulInputPhone
          id="example-playful-input-phone"
          inputClass="example-playful-input-phone"
          value={value}
          name="ExamplePlayfulInputPhone"
          valid={value.length > 5}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </StyledSpacingsStack>
    </CssReset>
  );
};

export const ExamplePlayfulInputPhoneMedium = () => {
  const [value, setValue] = useState("");

  return (
    <CssReset>
      <StyledMediumScaleWrapper>
        <PlayfulInputPhone
          id="example-playful-input-phone-medium"
          inputClass="example-playful-input-phone-medium"
          label="Name"
          scale="medium"
          value={value}
          name="ExamplePlayfulInputPhoneMedium"
          valid={value.length > 5}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </StyledMediumScaleWrapper>
    </CssReset>
  );
};

export const ExamplePlayfulInputPhoneMediumInvalid = () => {
  const [value, setValue] = useState("");

  return (
    <CssReset>
      <StyledMediumScaleWrapper>
        <PlayfulInputPhone
          id="example-playful-input-phone-medium-invalid"
          inputClass="example-playful-input-phone-medium-invalid"
          label="Name"
          scale="medium"
          value={value}
          name="ExamplePlayfulInputPhoneMediumInvalid"
          invalid={value.length <= 5}
          valid={value.length > 5}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </StyledMediumScaleWrapper>
    </CssReset>
  );
};

export const ExamplePlayfulInputPhoneDisabled = () => (
  <StyledSpacingsStack>
    <StyledDisabledFormLabel>Name</StyledDisabledFormLabel>
    <PlayfulInputPhone
      id="example-playful-input-phone-disabled"
      inputClass="example-playful-input-phone-disabled"
      value=""
      name="ExamplePlayfulInputPhoneDisabled"
      disabled
    />
  </StyledSpacingsStack>
);

export const ExamplePlayfulInputPhoneInvalid = () => {
  const [value, setValue] = useState("");

  return (
    <CssReset>
      <StyledSpacingsStack>
        <FormLabel>Name</FormLabel>
        <PlayfulInputPhone
          id="example-playful-input-phone-invalid"
          inputClass="example-playful-input-phone-invalid"
          value={value}
          name="ExamplePlayfulInputPhoneInvalid"
          invalid={value.length <= 5}
          valid={value.length > 5}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </StyledSpacingsStack>
    </CssReset>
  );
};
