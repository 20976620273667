export type Scale = "tiny" | "small" | "medium" | "big" | "huge" | "gigantic";

export type PassedProps = {
  scale?: Scale;
};

export type MappedProps = {
  height?: string;
  width?: string;
};

const getSizeByScale = (scale?: Scale) => {
  switch (scale) {
    case "gigantic":
      return "5em";
    case "huge":
      return "4em";
    case "big":
      return "3.5em";
    case "medium":
      return "3em";
    case "tiny":
      return "2em";
    case "small":
    default:
      return "2.5em";
  }
};

export const useMappedProps = ({
  scale,
  ...rest
}: PassedProps): MappedProps => ({
  ...rest,
  height: getSizeByScale(scale),
  width: getSizeByScale(scale),
});
