import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import ItServiceIcon from "../../../../../../packages/design-system/src/IndustryIcons/ItServiceIcon";
import * as industryIcons from "../../../../../../packages/design-system/src/IndustryIcons";
import * as React from 'react';
export default {
  Spacings,
  Text,
  ItServiceIcon,
  industryIcons,
  React
};