import transparentize from "polished/lib/color/transparentize";
import styled from "styled-components";

import { fontSizeByTextStyle } from "../Text";

const Chip = styled.button.attrs({
  isUncropped: true,
})`
  color: ${(props) => props.theme.palette.gray.dark};
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.gray[200]};
  background-color: ${(props) => props.theme.palette.backgrounds.white};
  outline: none;
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  padding: ${(props) => props.theme.spacings.small};
  cursor: pointer;
  font-size: ${(props) => fontSizeByTextStyle(props.theme).button2}rem;

  /* avoid wrapping chip text */
  white-space: nowrap;

  svg {
    color: ${(props) => props.theme.palette.brand[800]};
  }

  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.5, props.theme.palette.brand[400])};
  }

  &:hover {
    background: ${(props) => props.theme.palette.brand[100]};
  }
`;

export default Chip;
