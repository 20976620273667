import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css, StyledComponent } from "styled-components";

import CalendarGrid from "./CalendarGrid";

type DayProps = {
  selected?: boolean;
  isDisabled?: boolean;
};

const Day = styled(CalendarGrid.Content).attrs({
  as: "button",
})<DayProps>`
  border-radius: 50%;
  background: transparent;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.palette.gray[100]};
        `
      : css`
          &:hover {
            box-shadow: 0 0 0 3px
              ${transparentize(0.7, props.theme.palette.brand.main)};
          }
        `}
  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.palette.brand[100]};
      border-color: ${props.theme.palette.brand.main};
      color: ${props.theme.palette.brand[800]};
    `}
` as StyledComponent<"button", {}, DayProps>;

type CalendarMonthDayProps = {
  id: string;
  date: Date;
  focusable?: boolean;
  selected?: boolean;
  onClick?: (date: Date) => void;
  isDisabled: boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
};

const CalendarMonthDay: React.FC<CalendarMonthDayProps> = ({
  id,
  date,
  focusable,
  selected,
  isDisabled,
  onClick,
  onKeyUp,
}) => {
  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    if (onClick) {
      onClick(date);
    }
  };

  return (
    <Day
      type="button"
      selected={selected}
      tabIndex={focusable ? 0 : -1}
      id={id}
      aria-label={date.toLocaleDateString()}
      aria-selected={selected}
      onClick={handleClick}
      onKeyUp={onKeyUp}
      isDisabled={isDisabled}
    >
      {date.getDate()}
    </Day>
  );
};

export default CalendarMonthDay;
