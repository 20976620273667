import styled from "styled-components";

import Text from "../Text";

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  /* In IE11 flex-basis: 0% shrinks the element close to 0% width */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -ms-flex-preferred-size: auto;
`;

const Content = styled(Text).attrs({
  isUncropped: true,
})`
  display: inline-block;
  padding: ${(props) => props.theme.spacings.small};
  box-sizing: content-box;
  text-align: center;
  width: 2ch;
  height: 2ch;
  line-height: 1;
  border-width: ${(props) => props.theme.borders.width.medium}px;
  border-style: solid;
  border-color: transparent;
  font-feature-settings: "tnum" off;
`;

export default {
  Column,
  Content,
  Row,
};
