import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled from "styled-components";

import RadioChecked from "./RadioChecked.svg";

export const StyledRadioChecked = styled(RadioChecked)`
  color: inherit;
  border-radius: 50%;

  circle:last-child {
    visibility: hidden;
  }
`;

export const Container = styled.label`
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
  line-height: 1;
  display: inline-flex;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked + ${/* sc-selector */ StyledRadioChecked} {
    color: ${(props) => props.theme.palette.brand.dark};

    circle:last-child {
      visibility: visible;
    }
  }

  &:focus + ${/* sc-selector */ StyledRadioChecked} {
    box-shadow: 0 0 0 0.15em
      ${(props) => transparentize(0.7, props.theme.palette.brand.main)};
  }

  &:hover:not(:checked) + ${/* sc-selector */ StyledRadioChecked} {
    circle:first-child {
      fill: ${(props) => props.theme.palette.gray[50]};
    }
  }
`;

type RadioProps = Omit<
  JSX.IntrinsicElements["input"],
  "type" | "className" | "style"
> &
  Pick<JSX.IntrinsicElements["label"], "className" | "style">;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ className, style, ...inputProps }, ref) => (
    <Container className={className} style={style}>
      <Input type="radio" {...inputProps} ref={ref} />
      <StyledRadioChecked />
    </Container>
  ),
);

export default Radio;
