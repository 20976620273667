import Badge from "../../../../../../packages/design-system/src/Badge";
import Card from "../../../../../../packages/design-system/src/Card";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import * as React from 'react';
export default {
  Badge,
  Card,
  Spacings,
  Text,
  React
};