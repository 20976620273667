import React from "react";
import styled from "styled-components";

import InputBox, { InputBoxProps } from "../InputBox";

export const StyledFormTextarea = styled(InputBox)`
  padding: 10px 12px;
  box-sizing: border-box;
  font-feature-settings: normal;
  width: 100%;
  resize: vertical;
`;

type FormTextareaProps = InputBoxProps & JSX.IntrinsicElements["textarea"];

const FormTextarea = React.memo(
  React.forwardRef<HTMLTextAreaElement, FormTextareaProps>((props, ref) => (
    <StyledFormTextarea as="textarea" {...props} ref={ref} />
  )),
);

export default FormTextarea;
