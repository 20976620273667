import PhoneContactLink from "../../../../../../packages/design-system/src/PhoneContactLink";
import PhoneCircleIcon from "../../../../../../packages/design-system/src/Icons/PhoneCircleIcon";
import PhoneIcon from "../../../../../../packages/design-system/src/Icons/PhoneIcon";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import * as React from 'react';
export default {
  PhoneContactLink,
  PhoneCircleIcon,
  PhoneIcon,
  Spacings,
  React
};