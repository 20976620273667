import format from "date-fns/format";
import isValid from "date-fns/isValid";
import React from "react";

import Button from "../Button";
import Calendar from "../Calendar";
import Dialog from "../Dialog";
import Spacings from "../Spacings";
import Text from "../Text";

type DatePickerMobileProps = React.ComponentProps<typeof Calendar> & {
  visible: boolean;
  title?: string;
  placeholder?: string;
  close: () => void;
  closeLabel: string;
  toggleRef: React.MutableRefObject<HTMLButtonElement | null>;
  submitButtonText: React.ReactNode;
};

const DatePickerMobile: React.FC<DatePickerMobileProps> = ({
  visible,
  selectedDate: initialSelectedDate,
  title,
  placeholder,
  onDateSelect,
  closeLabel,
  toggleRef,
  close,
  submitButtonText,
  ...calendarProps
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    initialSelectedDate,
  );

  React.useEffect(() => {
    setSelectedDate(initialSelectedDate);
  }, [initialSelectedDate]);

  const handleDateSubmission = () => {
    if (selectedDate && onDateSelect) {
      onDateSelect(selectedDate);
    }
  };

  return (
    <Dialog
      closeLabel={closeLabel}
      visible={visible}
      toggleRef={toggleRef}
      header={
        <Spacings.Stack scale="medium">
          {title && (
            <Text textStyle="headline4" as="h1">
              {title}
            </Text>
          )}

          {selectedDate && isValid(selectedDate) ? (
            <Text>{format(selectedDate, "dd.MM.yyyy")}</Text>
          ) : (
            <Text priority="secondary">{placeholder}</Text>
          )}
        </Spacings.Stack>
      }
      content={
        <Spacings.Stack>
          <Calendar
            {...calendarProps}
            selectedDate={selectedDate}
            onDateSelect={setSelectedDate}
          />
          <Button type="button" onClick={handleDateSubmission}>
            {submitButtonText}
          </Button>
        </Spacings.Stack>
      }
      onClose={close}
    />
  );
};

export default DatePickerMobile;
