import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import Calendar from "../Calendar";
import Layer from "../Layer";
import Spacings from "../Spacings";

const ESC_KEY_CODE = 27;

type Position = "top" | "bottom";

const Container = styled(Layer.Overlay)<{ position: Position }>`
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  z-index: 2;
  position: absolute;
  left: 0;
  ${(props) =>
    props.position === "top"
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}
`;

type DatePickerDesktopProps = React.ComponentProps<typeof Calendar> & {
  visible: boolean;
  close: () => void;
  toggleRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const DatePickerDesktop: React.FC<DatePickerDesktopProps> = ({
  visible,
  close,
  toggleRef,
  ...calendarProps
}) => {
  const containerEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = (event: WindowEventMap["click"]) => {
      // containerEl contains a reference to the container, i.e. to the element where we don't want
      // to close the popup if the user clicks inside of it
      if (
        visible &&
        containerEl.current &&
        event.target &&
        !containerEl.current.contains(event.target as Node)
      ) {
        close();
      }
    };

    window.addEventListener("click", listener);

    return () => window.removeEventListener("click", listener);
  }, [visible, containerEl.current]);

  const [position, setPosition] = useState<Position>("bottom");
  useEffect(() => {
    const getPosition = () => {
      const yPositionOfToggleButton =
        toggleRef.current?.getBoundingClientRect().y;
      if (
        yPositionOfToggleButton &&
        yPositionOfToggleButton > window.innerHeight / 2
      ) {
        // If the toggle button is more than half-way down the viewport, then
        // we position the dialog on top.
        return "top";
      }
      // Otherwise we position the dialog on the bottom.
      return "bottom";
    };

    // Set the initial state
    setPosition(getPosition());

    // Re-evaluate the position when the user scrolls.
    const listener = () => {
      setPosition(getPosition());
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  if (!visible) {
    return null;
  }

  const handleContainerKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.keyCode === ESC_KEY_CODE) {
      close();
    }
  };

  return (
    <Container
      ref={containerEl}
      onKeyDown={handleContainerKeyDown}
      position={position}
    >
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <Spacings.Inset scale="medium">
          <Calendar {...calendarProps} />
        </Spacings.Inset>
      </FocusTrap>
    </Container>
  );
};

export default DatePickerDesktop;
