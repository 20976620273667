import React, { cloneElement } from "react";
import styled from "styled-components";

import Spacings from "../Spacings";

const StyledLayout = styled(Spacings.Stack)`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    flex-direction: row;
    justify-content: space-around;
    > * {
      margin: 0;
    }
  }
`;

export type FooterLinksProps = {
  children?: React.ReactNode;
};

export const FooterLinks: React.FC<FooterLinksProps> = ({ children }) => {
  const arrayOfChildren = React.Children.toArray(children);
  return (
    <StyledLayout as="ul" scale="big" alignItems="center">
      {React.Children.map(arrayOfChildren, (child, index) => {
        if (React.isValidElement(child)) {
          return <li key={index}>{cloneElement(child)}</li>;
        }
      })}
    </StyledLayout>
  );
};

export default FooterLinks;
