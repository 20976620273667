import React from "react";

import ResponsiveContainer from "../ResponsiveContainer";

export type LogoProps = {
  altText?: string;
  showSlogan?: boolean;
  smallLogo?: string;
  bigLogo?: string;
  longAlt?: string;
};

const Logo: React.FC<LogoProps> = ({
  altText = "Finanzchef24 GmbH Gewerbe einfach gut versichert",
  bigLogo = "//images.ctfassets.net/ta73me28xrs3/2Xzb7oYROFRXUwOTNnfPHg/260bd100f6c7bb3570853a5652762ced/brand-logo-slogan-colored.svg",
  showSlogan = true,
  smallLogo = "//images.ctfassets.net/ta73me28xrs3/3Sc0seQHUrzAu6pcck1Myz/44896f6112492b3b6e5731c14251c696/brand-logo-colored.svg",
}) => (
  <React.Fragment>
    <ResponsiveContainer showOn={["mobile"]}>
      <img src={smallLogo} alt={altText} height="40px" />
    </ResponsiveContainer>
    {showSlogan ? (
      <ResponsiveContainer showOn={["tablet", "desktop"]}>
        <img src={bigLogo} alt={altText} height="45px" />
      </ResponsiveContainer>
    ) : (
      <ResponsiveContainer showOn={["tablet", "desktop"]}>
        <img src={smallLogo} alt={altText} height="45px" />
      </ResponsiveContainer>
    )}
  </React.Fragment>
);

export default Logo;
