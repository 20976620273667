import styled, { css, keyframes } from "styled-components";

import DesignSystemTheme from "../designTokens";
import Text, { fontSizeByTextStyle } from "../Text";

export const StyledLabel = styled.label`
  position: relative;
`;

const animateLabelText = (theme: typeof DesignSystemTheme) => keyframes`
  from {
    font-size: ${fontSizeByTextStyle(theme).bodyProlonged}rem;
    top: 0;
    left: ${theme.spacings.medium};
  }

  to {
    font-size: ${fontSizeByTextStyle(theme).body}rem;
    top: calc(-100% - ${theme.spacings.big});
    left: 0;
  }
`;

export const StyledLabelText = styled(Text)`
  color: ${(props) => props.theme.palette.gray[500]};
  position: absolute;
  animation: ${(props) => animateLabelText(props.theme)} 0.1s ease forwards;
  animation-play-state: paused;
`;

export type InputStylesProps = {
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  scale?: "small" | "medium";
};

export const InputStyles = css<InputStylesProps>`
  width: 100%;
  box-sizing: border-box;
  font-size: ${(props) => fontSizeByTextStyle(props.theme).bodyProlonged}rem;
  color: ${(props) => props.theme.palette.gray[900]};
  background-color: ${(props) => props.theme.palette.white};
  outline: none;
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  border: ${(props) => props.theme.borders.width.medium}px solid
    ${(props) => props.theme.palette.gray[200]};
  padding: ${(props) =>
    props.scale === "medium"
      ? `${props.theme.spacings.medium}`
      : `calc(${props.theme.spacings.small} + ${props.theme.spacings.tiny}) ${props.theme.spacings.medium}`};

  ${(props) =>
    props.valid &&
    css`
      border-color: ${props.theme.palette.brand[200]};
    `};

  :hover {
    border-color: ${(props) => props.theme.palette.brand[300]};
  }

  :focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.brand[300]};
    border-color: ${(props) => props.theme.palette.brand[300]};

    + ${StyledLabelText} {
      animation-play-state: running;
    }
  }

  ${(props) =>
    props.invalid &&
    css`
      background-color: ${props.theme.palette.error[100]};
      border-color: ${props.theme.palette.error[500]};

      + ${StyledLabelText} {
        animation-play-state: running;
      }

      :hover {
        background-color: ${props.theme.palette.white};
        border-color: ${props.theme.palette.error[500]};
      }

      :focus {
        background-color: ${props.theme.palette.white};
        border-color: ${props.theme.palette.error[500]};
        box-shadow: 0 0 0 2px ${props.theme.palette.error[300]};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;
