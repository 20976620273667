import parseToRgb from "polished/lib/color/parseToRgb";
import toColorString from "polished/lib/color/toColorString";
import React from "react";
import styled from "styled-components";

import Spacings from "../Spacings";
import Text from "../Text";

export const Wrapper = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  padding: 32px;
  text-align: center;
`;

const Monospace = styled.div`
  font-family: monospace;
`;

export type Color = {
  color: string;
  isOnDarkBackground?: boolean;
  name: string;
};

export type ColorBoxesProps = {
  colors: Color[];
};

export const ColorBox: React.FC<Color> = ({
  color,
  isOnDarkBackground,
  name,
}) => (
  <Wrapper color={color}>
    <Spacings.Stack scale="medium">
      <Text isOnDarkBackground={isOnDarkBackground} textStyle="headline5">
        {name}
      </Text>
      <Monospace>
        <Text isOnDarkBackground={isOnDarkBackground}>{color}</Text>
      </Monospace>
      <Monospace>
        <Text isOnDarkBackground={isOnDarkBackground}>
          {toColorString(parseToRgb(color))}
        </Text>
      </Monospace>
    </Spacings.Stack>
  </Wrapper>
);

const ColorBoxes: React.FC<ColorBoxesProps> = ({ colors }) => (
  <Spacings.Inline scale="medium">
    {colors.map((color, index) => (
      <ColorBox {...color} key={index} />
    ))}
  </Spacings.Inline>
);

export default ColorBoxes;
