import React, { useState } from "react";
import styled from "styled-components";

import AddressSearch from ".";
import Spacings from "../Spacings";
import Text from "../Text";

const CssReset = styled(Spacings.Stack)`
  & ul {
    margin-left: 0 !important;
  }
`;

type Item = { id: string; label: string };
const items = [
  { id: "munich", label: "München" },
  { id: "london", label: "London" },
  { id: "sf", label: "San Francisco" },
];

export const AddressSearchExample: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [filteredValues, setFilteredValues] = useState<Item[]>(items);
  return (
    <CssReset>
      <label htmlFor="address-search">
        <Text>Select an address</Text>
      </label>
      <AddressSearch
        id="address-search"
        onSelect={(item) => {
          setSelectedItem(item);
        }}
        onInputValueChange={(newValue) => {
          setFilteredValues(
            items.filter((item) =>
              item.label.toLowerCase().includes(newValue.toLowerCase()),
            ),
          );
        }}
        selectedItem={selectedItem}
        visibleItems={filteredValues}
      />
    </CssReset>
  );
};
