import React from "react";
import styled, { css } from "styled-components";

import Link from "../Link";
import { SpacingScales } from "../Spacings/helpers";

type StyledLinkProps = {
  fontSize?: SpacingScales;
};

const StyledLink = styled(Link)<StyledLinkProps>`
  font-feature-settings: "tnum" off;
  ${(props) =>
    props.fontSize &&
    css`
      span {
        font-size: ${props.theme.spacings[props.fontSize]};
      }
    `};
`;

export type PhoneContactLinkProps = Omit<
  React.ComponentProps<typeof Link>,
  "href"
> & {
  children?: React.ReactNode;
  label?: string;
  phoneNumber: string;
  fontSize?: SpacingScales;
};

const PhoneContactLink: React.FC<PhoneContactLinkProps> = ({
  children,
  label,
  phoneNumber,
  fontSize,
  ...restProps
}) => {
  if (!phoneNumber) return null;
  return (
    <StyledLink
      aria-label={label || ""}
      href={`tel:${phoneNumber.replace(/ /gi, "")}`}
      fontSize={fontSize}
      {...restProps}
    >
      {children ? children : phoneNumber}
    </StyledLink>
  );
};

export default PhoneContactLink;
