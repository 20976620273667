import FormField from "../../../../../../packages/design-system/src/FormField";
import FormLabel from "../../../../../../packages/design-system/src/FormLabel";
import FormInput from "../../../../../../packages/design-system/src/FormInput";
import FormError from "../../../../../../packages/design-system/src/FormError";
import FormDescription from "../../../../../../packages/design-system/src/FormDescription";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import * as React from 'react';
export default {
  FormField,
  FormLabel,
  FormInput,
  FormError,
  FormDescription,
  Spacings,
  React
};