import React from "react";

import ResponsiveSwitch from "../ResponsiveSwitch";
import DatePickerDesktop from "./DatePickerDesktop";
import DatePickerMobile from "./DatePickerMobile";

type DatePickerDesktopProps = React.ComponentProps<typeof DatePickerDesktop>;
type DatePickerMobileProps = React.ComponentProps<typeof DatePickerMobile>;

type DatePickerProps = DatePickerDesktopProps & DatePickerMobileProps;

const DatePicker: React.FC<DatePickerProps> = (props) => (
  <ResponsiveSwitch
    mobile={<DatePickerMobile {...props} />}
    tablet={<DatePickerDesktop {...props} />}
    desktop={<DatePickerDesktop {...props} />}
  />
);

export default DatePicker;
