import Card from "../../../../../../packages/design-system/src/Card";
import Center from "../../../../../../packages/design-system/src/Center";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import ThreeDotLoader from "../../../../../../packages/design-system/src/ThreeDotLoader";
import * as React from 'react';
export default {
  Card,
  Center,
  Spacings,
  ThreeDotLoader,
  React
};