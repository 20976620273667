import { GetMenuPropsOptions, GetPropsCommonOptions } from "downshift";
import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import Layer from "../Layer";
import ResponsiveContainer from "../ResponsiveContainer";
import Spacings from "../Spacings";
import Text from "../Text";

export const StyledLayerDesktop = styled(Layer.Raised)`
  border-radius: ${(props) => props.theme.borders.radius.small}px;
  left: auto;
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  min-width: 100%;
`;

export const StyledLayerMobile = styled(Layer.Raised)`
  border-radius: 0 0 ${(props) => props.theme.borders.radius.small}px
    ${(props) => props.theme.borders.radius.small}px;
  left: 0;
  max-height: 50vh;
  overflow-y: auto;
  top: 0;
  position: absolute;
  min-width: 100%;
  z-index: ${(props) => props.theme.zIndexes.dialog};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zIndexes.dialog};
  background-color: ${(props) => props.theme.palette.backgrounds.transparent};
`;

export type DropdownMenuProps = {
  children: React.ReactNode;
  label: React.ReactNode;
  getMenuProps: (
    options?: GetMenuPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => any;
  as?: keyof JSX.IntrinsicElements;
  isOpen: boolean;
  onCloseMenu: () => any;
};

class DropdownMenu extends React.Component<DropdownMenuProps> {
  backdrop = React.createRef<HTMLDivElement>();
  componentDidUpdate() {
    if (this.props.isOpen && this.backdrop && this.backdrop.current) {
      this.backdrop.current.ontouchstart = this.onTouchBackdrop;
    }
  }
  // eslint-disable-next-line class-methods-use-this
  onTouchBackdrop = (e: TouchEvent) => {
    e.preventDefault();
  };
  render() {
    const {
      label,
      children,
      getMenuProps,
      as = "ul",
      isOpen,
      onCloseMenu,
    } = this.props;
    return (
      <React.Fragment>
        <ResponsiveContainer showOn={["mobile"]}>
          {typeof window !== "undefined" &&
            document.body &&
            createPortal(
              <React.Fragment>
                {isOpen && (
                  <Backdrop
                    data-testid="dropdown-backdrop"
                    ref={this.backdrop}
                    onTouchEnd={onCloseMenu}
                  />
                )}
                <StyledLayerMobile as={as} {...getMenuProps()}>
                  {isOpen && (
                    <Spacings.Inset scale="medium">
                      <Text textStyle="headline5">{label}</Text>
                    </Spacings.Inset>
                  )}
                  {children}
                </StyledLayerMobile>
              </React.Fragment>,
              document.body,
            )}
        </ResponsiveContainer>
        <ResponsiveContainer showOn={["tablet", "desktop"]}>
          <StyledLayerDesktop as={as} {...getMenuProps()}>
            {children}
          </StyledLayerDesktop>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

export default DropdownMenu;
