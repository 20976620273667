import React from "react";

import CalendarMonthWeek from "./CalendarMonthWeek";
import { MonthDaysMatrix } from "./createMonthDaysMatrix";

type CalendarMonthWeeksProps = {
  selectedDate?: Date;
  currentDate: Date;
  weeks: Readonly<MonthDaysMatrix>;
  dayId: React.ComponentProps<typeof CalendarMonthWeek>["dayId"];
  onDayClick?: React.ComponentProps<typeof CalendarMonthWeek>["onDayClick"];
  onDayKeyUp?: React.ComponentProps<typeof CalendarMonthWeek>["onDayKeyUp"];
};

const CalendarMonthWeeks: React.FC<CalendarMonthWeeksProps> = ({
  dayId,
  currentDate,
  selectedDate,
  onDayClick,
  onDayKeyUp,
  weeks,
}) => (
  <div role="grid">
    {weeks.map((days, weekIndex) => (
      <CalendarMonthWeek
        key={weekIndex}
        dayId={dayId}
        days={days}
        selectedDate={selectedDate}
        currentDate={currentDate}
        onDayClick={onDayClick}
        onDayKeyUp={onDayKeyUp}
      />
    ))}
  </div>
);

export default CalendarMonthWeeks;
