import styled from "styled-components";

import { StyledFormInput } from "../FormInput";
import FormInputGroup from "../FormInputGroup";

const StyledFormInputAffix = styled(StyledFormInput)`
  background-color: ${(props) => props.theme.palette.gray[50]};
  border-color: ${(props) => props.theme.palette.borders.main};
  width: auto;
  display: flex;
  align-items: center;
  ${/* sc-selector */ FormInputGroup} > &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    z-index: 0;
  }
  ${/* sc-selector */ FormInputGroup} > &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    z-index: 0;
  }
  :hover {
    border-color: ${(props) => props.theme.palette.borders.main};
  }
`;

export default StyledFormInputAffix;
