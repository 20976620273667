import { KeyboardArrowLeftIcon } from "../../../../../../packages/design-system/src/Icons/index.ts";
import Layer from "../../../../../../packages/design-system/src/Layer/index.tsx";
import Spacings from "../../../../../../packages/design-system/src/Spacings/index.tsx";
import Text from "../../../../../../packages/design-system/src/Text/index.tsx";
import ListItem from "../../../../../../packages/design-system/src/ListItem/index.tsx";
import * as React from 'react';
export default {
  KeyboardArrowLeftIcon,
  Layer,
  Spacings,
  Text,
  ListItem,
  React
};