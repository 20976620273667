import styled from "styled-components";

import { Theme } from "../designTokens";

export type DropdownMenuItemProps = {
  filterType?: string;
  isActive?: boolean;
  isSelected?: boolean;
};

const deriveBackgroundColorFromProps = (
  props: DropdownMenuItemProps & { theme: Theme },
) => {
  if (
    props.isActive &&
    props.isSelected &&
    props.filterType !== "FilterTypeSet"
  ) {
    return props.theme.palette.gray[200];
  }
  if (
    props.isActive ||
    (props.isSelected && props.filterType !== "FilterTypeSet")
  ) {
    return props.theme.palette.gray[100];
  }
  return "none";
};

const DropdownMenuItem = styled.li<DropdownMenuItemProps>`
  background-color: ${deriveBackgroundColorFromProps};
  display: block;
  word-break: normal;
`;

export default DropdownMenuItem;
