import React from "react";
import styled from "styled-components";

import Collapsible from "../Collapsible";

const StyledCollapsibleHeader = styled(Collapsible.Header)`
  padding: ${(props) => props.theme.spacings.small};
  background-color: ${(props) => props.theme.palette.backgrounds.gray};
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.main};
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;

  &:last-child {
    border-bottom-left-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
    border-bottom-right-radius: ${(props) =>
      props.theme.borders.radius.medium}px;
  }
`;
StyledCollapsibleHeader.displayName = Collapsible.Header.displayName;

const StyledCollapsibleContent = styled(Collapsible.Content)`
  padding: ${(props) => props.theme.spacings.small};
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.borders.main};
  border-top: 0;
  background-color: ${(props) => props.theme.palette.white};
  border-bottom-left-radius: ${(props) => props.theme.borders.radius.medium}px;
  border-bottom-right-radius: ${(props) => props.theme.borders.radius.medium}px;
`;
StyledCollapsibleContent.displayName = Collapsible.Content.displayName;

const BoxedCollapsible: typeof Collapsible = (props) => (
  <Collapsible {...props} />
);

BoxedCollapsible.Header = StyledCollapsibleHeader;
BoxedCollapsible.Headline = Collapsible.Headline;
BoxedCollapsible.Chevron = Collapsible.Chevron;
BoxedCollapsible.Content = StyledCollapsibleContent;

export default BoxedCollapsible;
