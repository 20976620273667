import React from "react";
import styled from "styled-components";

import Header from ".";
import EkomiFlag from "../EkomiFlag";
import IconCircle from "../IconCircle";
import CheckShieldIcon from "../Icons/CheckShieldIcon";
import CrownIcon from "../Icons/CrownIcon";
import CursorIcon from "../Icons/CursorIcon";
import KeyboardArrowReturnIcon from "../Icons/KeyboardArrowReturnIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import ResponsiveContainer from "../ResponsiveContainer";
import { TrustBar, TrustItem } from "../TrustBar";

const CssReset = styled.div`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  height: 200px;
  & ul,
  li {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
  img {
    margin-bottom: 0 !important;
  }
`;

const HeaderExample: React.FC = () => (
  <CssReset>
    <Header
      ekomiFlag={<EkomiFlag>Top eKomi-Bewertungen</EkomiFlag>}
      ekomiImageBig={
        <IconCircle color="brand">
          <CrownIcon color="brand" scale="huge" />
        </IconCircle>
      }
      ekomiImageSmall={
        <IconCircle color="brand">
          <CrownIcon color="brand" scale="huge" />
        </IconCircle>
      }
      trustBar={
        <TrustBar>
          <TrustItem icon={<CheckShieldIcon color="brand" />}>
            Unabhängiger Vergleich
          </TrustItem>
          <ResponsiveContainer showOn={["desktop"]}>
            <TrustItem icon={<PhoneIcon color="brand" />}>
              Kostenlose Beratung per Telefon
            </TrustItem>
          </ResponsiveContainer>
          <TrustItem icon={<CursorIcon color="brand" />}>
            Einfacher online-Abschluss
          </TrustItem>
          <TrustItem icon={<KeyboardArrowReturnIcon color="brand" />}>
            14 Tage Widerrufsrecht
          </TrustItem>
        </TrustBar>
      }
    />
  </CssReset>
);
export default HeaderExample;
