import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import React, { createRef } from "react";
import styled, { css } from "styled-components";

import FormInput from "../FormInput";
import InputBox, { InputBoxProps } from "../InputBox";
import Layer from "../Layer";
import Spacings from "../Spacings";
import Text from "../Text";
import ThreeDotLoader from "../ThreeDotLoader";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledItem = styled(Spacings.InsetSquish)`
  position: relative;
  display: block;
`;

const ActionableStyledItem = styled(StyledItem)<{ isHighlighted: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isHighlighted
      ? css`
          background-color: ${props.theme.palette.brand[200]};
        `
      : ""};
`;

const StyledMenu = styled(Layer.Raised)<{
  isOpen?: boolean;
  usePxUnits?: boolean;
}>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndexes.dialog};
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.white};
  max-height: ${(props) => (props.usePxUnits ? "320px" : "20rem")};
  /* avoid overflow when the menu is not open for whatever reason leads to IE11
  rendering a infinitely high empty list that overlays the rest of the page
  https://finanzchef24.atlassian.net/browse/FC-18767 */
  overflow-y: ${(props) => (props.isOpen ? "auto" : "hidden")};
  overflow-x: hidden;
  outline: 0;
  border-radius: 0 0 ${(props) => props.theme.borders.radius.medium}px
    ${(props) => props.theme.borders.radius.medium}px;
  border: ${(props) => props.theme.borders.width.small}px solid
    ${(props) => props.theme.palette.brand[500]};
  border-top-width: 0;
  ${(props) =>
    props.isOpen
      ? ""
      : css`
          border: none;
        `}
`;

const StyledSelectedItem = styled(Spacings.InsetSquish)`
  border-radius: ${(props) => props.theme.borders.radius.small}px;
  background-color: ${(props) => props.theme.palette.brand[200]};
  margin: 3px 0 3px 3px;
  height: 100%;
`;

const StyledInputBox = styled(InputBox)<{ isOpen?: boolean }>`
  padding: 0;
  ${(props) =>
    props.isOpen
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ""}
`;

const StyledInput = styled(FormInput)<{ isOpen?: boolean }>`
  border: none;
  width: auto;
  flex-grow: 1;
  &:focus,
  &:focus-within {
    box-shadow: none;
  }

  ${(props) =>
    props.isOpen
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ""}
`;

const StyledWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledThreeDotLoader = styled(ThreeDotLoader).attrs({
  scale: "small",
})`
  margin: auto 20px auto 4px;
`;

type Item = { id: string; label: string };

export type AddressSearchProps<I> = InputBoxProps &
  Pick<
    JSX.IntrinsicElements["input"],
    "id" | "name" | "onBlur" | "placeholder" | "disabled"
  > & {
    isLoading?: boolean;
    onInputValueChange: (inputValue: string) => void;
    visibleItems: I[];
    labelId?: string;
    usePxUnits?: boolean;
    onSelect: (selectedItem: I | null) => void;
    selectedItem: I | null;
  };

class AddressSearch<I extends Item = Item> extends React.Component<
  AddressSearchProps<I>
> {
  input = createRef<HTMLInputElement>();

  // eslint-disable-next-line class-methods-use-this
  stateReducer = (state: DownshiftState<I>, changes: StateChangeOptions<I>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          inputValue: state.inputValue,
          isOpen: false,
        };
      // In case the user clicks somewhere else, the menu should keep its state
      // and not close, to still allow the user to select an item.
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
        return { ...state };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem: I | null) => {
    this.props.onSelect(selectedItem);
  };

  render() {
    return (
      <Downshift
        itemToString={(item: I | null) => (item ? item.label : "")}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        initialSelectedItem={this.props.selectedItem || undefined}
        labelId={this.props.labelId}
        initialInputValue={this.props.selectedItem?.label || ""}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          toggleMenu,
        }) => {
          const visibleItems = this.props.visibleItems;
          const isOpenAndHasVisibleItems = isOpen && visibleItems.length > 0;
          return (
            <div>
              <StyledWrapper>
                <StyledInputBox
                  isOpen={isOpenAndHasVisibleItems}
                  data-testid="address-search"
                  invalid={this.props.invalid}
                  onClick={() => {
                    toggleMenu();
                  }}
                >
                  <StyledWrapContainer>
                    {this.props.selectedItem && !this.props.disabled ? (
                      <StyledSelectedItem
                        key={this.props.selectedItem.id}
                        scale="tiny"
                        onClick={() => {
                          this.handleSelection(null);
                        }}
                      >
                        <Spacings.Inline
                          alignItems="center"
                          usePxUnits={this.props.usePxUnits}
                        >
                          <Text
                            textStyle="bodyProlonged"
                            isUncropped
                            usePxFontSize={this.props.usePxUnits}
                          >
                            {this.props.selectedItem.label}
                          </Text>
                        </Spacings.Inline>
                      </StyledSelectedItem>
                    ) : (
                      <StyledInput
                        {...getInputProps({
                          id: this.props.id,
                          disabled: this.props.disabled,
                          isOpen: isOpenAndHasVisibleItems,
                          name: this.props.name,
                          onBlur: this.props.onBlur,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            this.props.onInputValueChange(event.target.value);
                          },
                          placeholder: !this.props.selectedItem
                            ? this.props.placeholder
                            : undefined,
                          ref: this.input,
                          htmlFor: this.props.labelId,
                        })}
                        as={FormInput}
                      />
                    )}
                    {this.props.isLoading && <StyledThreeDotLoader isActive />}
                  </StyledWrapContainer>
                </StyledInputBox>
                <StyledMenu
                  data-testid="autocomplete-list-box"
                  isOpen={isOpenAndHasVisibleItems}
                  usePxUnits={this.props.usePxUnits}
                  {...getMenuProps()}
                >
                  {isOpen && (
                    <>
                      <ul>
                        {visibleItems.map((item, index) => (
                          <ActionableStyledItem
                            as="li"
                            isHighlighted={highlightedIndex === index}
                            {...getItemProps({
                              key: item.id,
                              index,
                              item,
                            })}
                          >
                            <Text
                              textStyle="bodyProlonged"
                              isUncropped
                              usePxFontSize={this.props.usePxUnits}
                            >
                              {item.label}
                            </Text>
                          </ActionableStyledItem>
                        ))}
                      </ul>
                    </>
                  )}
                </StyledMenu>
              </StyledWrapper>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export default AddressSearch;
