import styled from "styled-components";

import FormInput from "./";

export const CustomFormInput = styled(FormInput)`
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
`;
