import ChevronRightIcon from "../../../../../../packages/design-system/src/Icons/ChevronRightIcon";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import Button, { UnstyledButton } from "../../../../../../packages/design-system/src/Button/index.tsx";
import * as React from 'react';
export default {
  ChevronRightIcon,
  Spacings,
  Text,
  Button,
  UnstyledButton,
  React
};