import React from "react";

import PlayfulInputMedium from "./PlayfulInputMedium";
import PlayfulInputSmall, { PlayfulInputProps } from "./PlayfulInputSmall";

const PlayfulInput = React.memo(
  React.forwardRef<HTMLInputElement, PlayfulInputProps>(
    ({ label, scale = "small", ...props }, ref) =>
      scale === "small" ? (
        <PlayfulInputSmall {...props} ref={ref} />
      ) : (
        <PlayfulInputMedium label={label}>
          <PlayfulInputSmall {...props} scale={scale} ref={ref} />
        </PlayfulInputMedium>
      ),
  ),
);

export default PlayfulInput;
