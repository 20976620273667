import React from "react";
import styled from "styled-components";

import { Theme } from "../designTokens";
import InputBox, { InputBoxProps } from "../InputBox";

type FormSelectProps = InputBoxProps & JSX.IntrinsicElements["select"];

const FormSelect = React.memo(
  React.forwardRef<HTMLSelectElement, FormSelectProps>((props, ref) => (
    <InputBox as="select" {...props} ref={ref} />
  )),
);

export const getHeight = (theme: Theme): string => {
  const defaultSelectHeight = 24;

  // We take the width from the left and right that is why those values are doubled.
  const verticalPaddingWidth = theme.insetSquish.spacings.small * 2;

  return `${defaultSelectHeight + verticalPaddingWidth}px`;
};

const StyledFormSelect = styled(FormSelect)`
  /* We cannot use paddings with select that is why we set height */
  height: ${(props) => getHeight(props.theme)};
`;

export default StyledFormSelect;
