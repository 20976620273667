import React from "react";

import MultiplePersonsIcon from "../Icons/MultiplePersonsIcon";
import SinglePersonIcon from "../Icons/SinglePersonIcon";
import PlayfulInputGroup from "../PlayfulInputGroup";
import Spacings from "../Spacings";
import PlayfulCheckbox from "./";

export const ExamplePlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox name="answer" value="long-answer">
      I enjoy answering questions with a really long speach that nobody can
      follow what I am talking about 🤓
    </PlayfulCheckbox>
    <PlayfulCheckbox name="answer" value="short-answer">
      I just answer questions 🤷‍♂️
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ExamplePlayfulCheckboxMedium: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox name="answer" scale="medium">
      I enjoy answering questions with a really long speach that nobody can
      follow what I am talking about 🤓
    </PlayfulCheckbox>
    <PlayfulCheckbox name="answer" value="short-answer" scale="medium">
      I just answer questions 🤷‍♂️
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const DisabledPlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox name="error-answer" value="long-answer" disabled>
      I enjoy answering questions with a really long speach that nobody can
      follow what I am talking about 🤓
    </PlayfulCheckbox>
    <PlayfulCheckbox name="error-answer" value="short-answer" disabled>
      I just answer questions 🤷‍♂️
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ErrorPlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox name="error-answer" value="long-answer" invalid>
      I enjoy answering questions with a really long speach that nobody can
      follow what I am talking about 🤓
    </PlayfulCheckbox>
    <PlayfulCheckbox name="error-answer" value="short-answer" invalid>
      I just answer questions 🤷‍♂️
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ExampleWithIconPlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox
      name="icon-answer"
      value="icon-answer-true"
      icon={<SinglePersonIcon />}
    >
      Yes
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="icon-answer"
      value="icon-answer-false"
      icon={<MultiplePersonsIcon />}
    >
      Some very very long label
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ExampleWithIconPlayfulCheckboxMedium: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox
      name="icon-answer"
      value="icon-answer-true"
      icon={<SinglePersonIcon />}
      scale="medium"
    >
      Yes
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="icon-answer"
      value="icon-answer-false"
      icon={<MultiplePersonsIcon />}
      scale="medium"
    >
      Some very very long label
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ExampleErrorWithIconPlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox
      name="icon-error-answer"
      value="icon-error-answer-true"
      icon={<SinglePersonIcon />}
      invalid
    >
      Yes
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="icon-error-answer"
      value="icon-error-answer-false"
      icon={<MultiplePersonsIcon />}
      invalid
    >
      no
    </PlayfulCheckbox>
  </Spacings.Stack>
);

export const ExampleGroupPlayfulCheckbox: React.FC = () => (
  <PlayfulInputGroup>
    <PlayfulCheckbox
      name="group-answer"
      value="group-answer-true"
      icon={<SinglePersonIcon />}
    >
      Yes
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="group-answer"
      value="group-answer-false"
      icon={<MultiplePersonsIcon />}
    >
      no
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="group-answer"
      value="group-answer-true2"
      icon={<SinglePersonIcon />}
    >
      This is an example with a very long label
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="group-answer"
      value="group-answer-false3"
      icon={<MultiplePersonsIcon />}
    >
      no
    </PlayfulCheckbox>
  </PlayfulInputGroup>
);

export const LeanPlayfulCheckbox: React.FC = () => (
  <Spacings.Stack>
    <PlayfulCheckbox
      name="error-answer"
      value="long-answer"
      leanCheckboxOptions
    >
      I enjoy answering questions with a really long speach that nobody can
      follow what I am talking about 🤓
    </PlayfulCheckbox>
    <PlayfulCheckbox
      name="error-answer"
      value="short-answer"
      leanCheckboxOptions
    >
      I just answer questions 🤷‍♂️
    </PlayfulCheckbox>
  </Spacings.Stack>
);
