import { css, DefaultTheme } from "styled-components";

import { fontSizeByTextStyle } from "../Text";

type Scale = "small" | "medium";

type LabelStylesProps = {
  disabled?: boolean;
  hasIcon?: boolean;
  theme: DefaultTheme;
  leanCheckboxOptions?: boolean;
  scale?: Scale;
};

export const ICON_LABEL_SPACING_SMALL = (props: LabelStylesProps) =>
  props.theme.spacings.small;
export const ICON_LABEL_SPACING_BIG = (props: LabelStylesProps) =>
  props.theme.spacings.big;
export const SVG_SIZE = "28px";

export const LabelStyles = css<LabelStylesProps>`
  display: flex;
  padding: ${(props) =>
    props.scale === "medium"
      ? props.theme.spacings.medium
      : props.theme.spacings.small};
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: ${(props) => fontSizeByTextStyle(props.theme).body}rem;
  max-width: none;

  ${(props) =>
    props.leanCheckboxOptions &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${(props) =>
    props.hasIcon &&
    css`
      padding: ${ICON_LABEL_SPACING_BIG} ${ICON_LABEL_SPACING_SMALL};
      flex-direction: column;
    `}

    ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

type LabelTextStylesProps = {
  hasIcon?: boolean;
};

export const LabelTextStyles = css<LabelTextStylesProps>`
  line-height: 20px;
  align-self: center;
  ${(props) =>
    props.hasIcon &&
    css`
      text-align: center;
    `}
`;

type InputSvgStylesProps = {
  hasIcon?: boolean;
};

export const InputSvgStyles = css<InputSvgStylesProps>`
  margin-right: ${(props) => props.theme.spacings.small};
  font-size: ${SVG_SIZE};
  flex-shrink: 0;

  ${(props) =>
    props.hasIcon &&
    css`
      position: absolute;
      left: ${ICON_LABEL_SPACING_SMALL};
      top: ${ICON_LABEL_SPACING_BIG};
    `}
`;

export const IconContainerStyles = css`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.palette.gray[400]};
  margin-bottom: ${(props) => props.theme.spacings.small};
  font-size: ${SVG_SIZE};
  svg {
    color: inherit;
  }
`;

type BackgroundStylesProps = {
  leanCheckboxOptions?: boolean;
  scale?: Scale;
};
export const BackgroundStyles = css<BackgroundStylesProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
  border-radius: ${(props) => props.theme.borders.radius.medium}px;
  z-index: -1;

  ${(props) =>
    props.leanCheckboxOptions
      ? css`
          background: transparent;
        `
      : css`
          background: ${props.scale === "medium"
            ? props.theme.palette.white
            : props.theme.palette.gray[50]};
        `}
`;
