import React from "react";
import styled from "styled-components";

import { InputStyles, InputStylesProps } from "../PlayfulStyles/InputStyles";

const StyledInput = styled.input<InputStylesProps>`
  ${InputStyles};
`;

export type PlayfulInputProps = {
  label?: string;
  ref?: React.Ref<HTMLInputElement>;
} & InputStylesProps &
  JSX.IntrinsicElements["input"];

const PlayfulInputSmall = React.forwardRef<HTMLInputElement, PlayfulInputProps>(
  ({ disabled, invalid, label, scale, valid, ...props }, ref) => (
    <StyledInput
      {...props}
      scale={scale}
      disabled={disabled}
      invalid={invalid}
      valid={valid}
      ref={ref}
    />
  ),
);

export default PlayfulInputSmall;
