import styled from "styled-components";

const RadioGroup = styled.div`
  position: relative;
  z-index: 0;

  > * + * {
    margin-top: -${(props) => props.theme.borders.width.small}px;
  }
`;

export default RadioGroup;
