import IconCircle from "../../../../../../packages/design-system/src/IconCircle/index.tsx";
import DownloadIcon from "../../../../../../packages/design-system/src/Icons/DownloadIcon";
import * as genericIcons from "../../../../../../packages/design-system/src/Icons";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import * as React from 'react';
export default {
  IconCircle,
  DownloadIcon,
  genericIcons,
  Spacings,
  Text,
  React
};