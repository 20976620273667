import React from "react";
import styled from "styled-components";

import ListItem from "../ListItem";
import Spacings from "../Spacings";
import Text from "../Text";

const StyledListItemText = styled(Text)`
  text-transform: uppercase;
`;

export const TrustItem: React.FC<{
  icon: React.ReactNode;
  children?: React.ReactNode;
}> = ({ children, icon }) => (
  <ListItem scale="small" left={icon} as="li">
    <StyledListItemText priority="secondary" textStyle="caption">
      {children}
    </StyledListItemText>
  </ListItem>
);

const TrustBarLayout = styled(Spacings.Inline)`
  justify-content: space-between;
`;

export const TrustBar: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <Spacings.Inset>
    <TrustBarLayout as="ul">{children}</TrustBarLayout>
  </Spacings.Inset>
);
