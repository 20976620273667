import React from "react";
import styled from "styled-components";

import Text from "../../src/Text";

const EkomiFlagText = styled(Text)`
  /* to avoid text overflowing the container in IE11 */
  width: 100%;
  line-height: ${(props) => props.theme.typography.lineHeights.headline};
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    text-align: center;
  }
`;

const EkomiFlag: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <EkomiFlagText textStyle="caption" priority="secondary">
    {children}
  </EkomiFlagText>
);

export default EkomiFlag;
