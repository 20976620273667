import InfoBox from "../../../../../../packages/design-system/src/InfoBox";
import Text from "../../../../../../packages/design-system/src/Text";
import DownloadIcon from "../../../../../../packages/design-system/src/Icons/DownloadIcon";
import InfoIcon from "../../../../../../packages/design-system/src/Icons/InfoIcon";
import InformationCircleIcon from "../../../../../../packages/design-system/src/Icons/InformationCircleIcon";
import IconCircle from "../../../../../../packages/design-system/src/IconCircle";
import * as React from 'react';
export default {
  InfoBox,
  Text,
  DownloadIcon,
  InfoIcon,
  InformationCircleIcon,
  IconCircle,
  React
};