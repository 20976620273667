import Breadcrumbs from "../../../../../../packages/design-system/src/Breadcrumbs";
import Footer from "../../../../../../packages/design-system/src/Footer";
import HomeIcon from "../../../../../../packages/design-system/src/Icons/HomeIcon";
import { CssReset } from "../../../../../../packages/design-system/src/Breadcrumbs/readme-helpers";
import ChevronRightIcon from "../../../../../../packages/design-system/src/Icons/ChevronRightIcon";
import * as React from 'react';
export default {
  Breadcrumbs,
  Footer,
  HomeIcon,
  CssReset,
  ChevronRightIcon,
  React
};