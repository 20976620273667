import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import getDate from "date-fns/getDate";
import isSameMonth from "date-fns/isSameMonth";
import isThisMonth from "date-fns/isThisMonth";
import setDate from "date-fns/setDate";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import React from "react";

type DeriveCurrentDateDayOptions = {
  desiredCurrentDate: Date;
  today: Date;
  selectedDate: Date | null | undefined;
};

const deriveCurrentDateDay = ({
  desiredCurrentDate,
  selectedDate,
  today,
}: DeriveCurrentDateDayOptions) => {
  if (selectedDate && isSameMonth(desiredCurrentDate, selectedDate)) {
    return getDate(selectedDate);
  }

  return isThisMonth(desiredCurrentDate) ? getDate(today) : 1;
};

const deriveCurrentDate = (date: Date | null | undefined, today: Date) =>
  date || today;

type UseCalendarOptions = {
  selectedDate?: Date;
};

const useCalendar = ({ selectedDate }: UseCalendarOptions = {}) => {
  const prevSelectedDateRef = React.useRef<Date>();
  const todayDate = new Date();

  const [currentDate, setCurrentDate] = React.useState(
    deriveCurrentDate(selectedDate, todayDate),
  );

  // Reconfigure the calendar state when the selectedDate changed.
  React.useEffect(() => {
    if (
      prevSelectedDateRef.current &&
      prevSelectedDateRef.current !== selectedDate
    ) {
      setCurrentDate(deriveCurrentDate(selectedDate, todayDate));
    }

    prevSelectedDateRef.current = selectedDate;
  }, [selectedDate, prevSelectedDateRef.current]);

  const toPrevMonthDate = () => {
    const prevMonthDate = subMonths(currentDate, 1);
    const prevMonthDateWithCorrectedDay = setDate(
      prevMonthDate,
      deriveCurrentDateDay({
        desiredCurrentDate: prevMonthDate,
        selectedDate,
        today: todayDate,
      }),
    );

    setCurrentDate(prevMonthDateWithCorrectedDay);

    return prevMonthDateWithCorrectedDay;
  };

  const toNextMonthDate = () => {
    const nextMonthDate = addMonths(currentDate, 1);
    const nextMonthDateWithCorrectedDay = setDate(
      nextMonthDate,
      deriveCurrentDateDay({
        desiredCurrentDate: nextMonthDate,
        selectedDate,
        today: todayDate,
      }),
    );

    setCurrentDate(nextMonthDateWithCorrectedDay);

    return nextMonthDateWithCorrectedDay;
  };

  const toPrevDate = () => {
    const prevDate = subDays(currentDate, 1);

    setCurrentDate(prevDate);

    return prevDate;
  };

  const toNextDate = () => {
    const nextDate = addDays(currentDate, 1);

    setCurrentDate(nextDate);

    return nextDate;
  };

  const to7DaysEarlierDate = () => {
    const nextDate = subDays(currentDate, 7);

    setCurrentDate(nextDate);

    return nextDate;
  };

  const to7DaysLaterDate = () => {
    const nextDate = addDays(currentDate, 7);

    setCurrentDate(nextDate);

    return nextDate;
  };

  return {
    currentDate,
    setCurrentDate,
    to7DaysEarlierDate,
    to7DaysLaterDate,
    toNextDate,
    toNextMonthDate,
    toPrevDate,
    toPrevMonthDate,
  };
};

export default useCalendar;
