import React from "react";

import StarIcon from "../Icons/svg/star.svg";
import { NoShrink } from "../ListItem";
import Rating from "../Rating";
import Spacings from "../Spacings";
import Text from "../Text";

export const EKOMI_RATING = 4.9 / 5;
const EKOMI_RATING_STRING = "4.9 / 5";

const EkomiRating: React.FC<{
  isCondensed?: boolean;
  scale?: React.ComponentProps<typeof Rating>["iconScale"];
}> = ({ isCondensed, scale }) => (
  <Spacings.Inline alignItems="center">
    <Rating icon={StarIcon} score={EKOMI_RATING * 100} iconScale={scale} />
    {!isCondensed ? (
      <NoShrink>
        <Text textStyle="caption" priority="secondary">
          {EKOMI_RATING_STRING}
        </Text>
      </NoShrink>
    ) : null}
  </Spacings.Inline>
);

export default EkomiRating;
