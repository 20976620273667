import Chip from "../../../../../../packages/design-system/src/Chip";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import CloseIcon from "../../../../../../packages/design-system/src/Icons/CloseIcon";
import * as React from 'react';
export default {
  Chip,
  Spacings,
  Text,
  CloseIcon,
  React
};