import theme from "../../../../../../packages/design-system/src/designTokens.ts";
import Spacings from "../../../../../../packages/design-system/src/Spacings";
import Text from "../../../../../../packages/design-system/src/Text";
import * as circularIcons from "../../../../../../packages/design-system/src/CircularIcons";
import * as React from 'react';
export default {
  theme,
  Spacings,
  Text,
  circularIcons,
  React
};