import Spacings from "../../../../../../packages/design-system/src/Spacings/index.tsx";
import Layer from "../../../../../../packages/design-system/src/Layer";
import Text from "../../../../../../packages/design-system/src/Text";
import Card from "../../../../../../packages/design-system/src/Card/index.tsx";
import * as React from 'react';
export default {
  Spacings,
  Layer,
  Text,
  Card,
  React
};