import React from "react";
import styled from "styled-components";

import Radio from "../Radio";
import Text, { lineHeightByTextStyle, TextStyles } from "../Text";

export const CHECKED_CLASS_NAME = "checked";

export const StyledLabel = styled(Text).attrs({
  as: "label",
  isUncropped: true,
})`
  display: flex;
`;

type StyledRadioProps = {
  textStyle: NonNullable<TextStyles>;
};

export const StyledRadio = styled(Radio)<StyledRadioProps>`
  margin-right: ${(props) => props.theme.spacings.small};

  /* Makes the radio button the same size as the line-height  */
  font-size: ${(props) =>
    lineHeightByTextStyle(props.theme)[props.textStyle]}em;
  flex-shrink: 0;
`;

export const LabelText = styled.span`
  flex-grow: 1;
`;

type RadioProps = React.ComponentProps<typeof Radio>;

type LabelledRadioProps = RadioProps & {
  label: React.ReactNode;
  textStyle?: TextStyles;
};

const LabelledRadio: React.FC<LabelledRadioProps> = ({
  label,
  textStyle = "body",
  className = "",
  checked,
  ...radioProps
}) => (
  <StyledLabel
    textStyle={textStyle}
    className={`${className} ${checked ? CHECKED_CLASS_NAME : ""}`}
  >
    <StyledRadio {...radioProps} textStyle={textStyle} checked={checked} />
    <LabelText>{label}</LabelText>
  </StyledLabel>
);

export default LabelledRadio;
