import React from "react";

import MultiplePersonsIcon from "../Icons/MultiplePersonsIcon";
import SinglePersonIcon from "../Icons/SinglePersonIcon";
import PlayfulInputGroup from "../PlayfulInputGroup";
import Spacings from "../Spacings";
import PlayfulRadio from "./";

export const ExamplePlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("long-answer");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="answer"
        value="long-answer"
        checked={value === "long-answer"}
        onChange={handleChange}
      >
        I enjoy answering questions with a really long speach that nobody can
        follow what I am talking about 🤓
      </PlayfulRadio>
      <PlayfulRadio
        name="answer"
        value="short-answer"
        checked={value === "short-answer"}
        onChange={handleChange}
      >
        I just answer questions 🤷‍♂️
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const ExamplePlayfulRadioMedium: React.FC = () => {
  const [value, setValue] = React.useState("long-answer");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="medium-answer"
        value="long-answer"
        checked={value === "long-answer"}
        onChange={handleChange}
        scale="medium"
      >
        I enjoy answering questions with a really long speach that nobody can
        follow what I am talking about 🤓
      </PlayfulRadio>
      <PlayfulRadio
        name="medium-answer"
        value="short-answer"
        checked={value === "short-answer"}
        onChange={handleChange}
        scale="medium"
      >
        I just answer questions 🤷‍♂️
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const DisabledPlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="error-answer"
        value="long-answer"
        checked={value === "long-answer"}
        disabled
        onChange={handleChange}
      >
        I enjoy answering questions with a really long speach that nobody can
        follow what I am talking about 🤓
      </PlayfulRadio>
      <PlayfulRadio
        name="error-answer"
        value="short-answer"
        checked={value === "short-answer"}
        disabled
        onChange={handleChange}
      >
        I just answer questions 🤷‍♂️
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const ErrorPlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="error-answer"
        value="long-answer"
        checked={value === "long-answer"}
        invalid={value === ""}
        onChange={handleChange}
      >
        I enjoy answering questions with a really long speach that nobody can
        follow what I am talking about 🤓
      </PlayfulRadio>
      <PlayfulRadio
        name="error-answer"
        value="short-answer"
        checked={value === "short-answer"}
        invalid={value === ""}
        onChange={handleChange}
      >
        I just answer questions 🤷‍♂️
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const ExampleWithIconPlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("icon-answer-true");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="icon-answer"
        value="icon-answer-true"
        checked={value === "icon-answer-true"}
        onChange={handleChange}
        icon={<SinglePersonIcon />}
      >
        Yes
      </PlayfulRadio>
      <PlayfulRadio
        name="icon-answer"
        value="icon-answer-false"
        checked={value === "icon-answer-false"}
        onChange={handleChange}
        icon={<MultiplePersonsIcon />}
      >
        Some very very long label
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const ExampleErrorWithIconPlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <Spacings.Stack>
      <PlayfulRadio
        name="icon-error-answer"
        value="icon-error-answer-true"
        checked={value === "icon-error-answer-true"}
        onChange={handleChange}
        icon={<SinglePersonIcon />}
        invalid={value === ""}
      >
        Yes
      </PlayfulRadio>
      <PlayfulRadio
        name="icon-error-answer"
        value="icon-error-answer-false"
        checked={value === "icon-error-answer-false"}
        onChange={handleChange}
        icon={<MultiplePersonsIcon />}
        invalid={value === ""}
      >
        no
      </PlayfulRadio>
    </Spacings.Stack>
  );
};

export const ExampleGroupPlayfulRadio: React.FC = () => {
  const [value, setValue] = React.useState("group-answer-true");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <PlayfulInputGroup>
      <PlayfulRadio
        name="group-answer"
        value="group-answer-true"
        checked={value === "group-answer-true"}
        onChange={handleChange}
        icon={<SinglePersonIcon />}
      >
        Yes
      </PlayfulRadio>
      <PlayfulRadio
        name="group-answer"
        value="group-answer-false"
        checked={value === "group-answer-false"}
        onChange={handleChange}
        icon={<MultiplePersonsIcon />}
      >
        no
      </PlayfulRadio>
      <PlayfulRadio
        name="group-answer"
        value="group-answer-true2"
        checked={value === "group-answer-true2"}
        onChange={handleChange}
        icon={<SinglePersonIcon />}
      >
        This is an example with a very long label
      </PlayfulRadio>
      <PlayfulRadio
        name="group-answer"
        value="group-answer-false3"
        checked={value === "group-answer-false3"}
        onChange={handleChange}
        icon={<MultiplePersonsIcon />}
      >
        no
      </PlayfulRadio>
    </PlayfulInputGroup>
  );
};
